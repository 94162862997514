
  .navbar-logo {
    color: whitesmoke;
    text-decoration: none;
    font-size: 2.3rem;
    display: flex;
    align-items: center;
    width: 9rem;
    justify-content: space-between;
  }
  
  
  .nav-item {
    list-style: none;

  }
  
  .nav-item a {
    text-decoration: none;
    color: var(--black);
    cursor: pointer;
    font-weight: 400;
    transition: 0.2s all ease-out;
  }
  
  .nav-item a:hover {
    color: var(--primary);
  }
  
  .sidebar-toggle {
    width: 65vw;
    display: flex;
    justify-content: flex-end;
  }
  
  .sidebar-toggle-logo {
    font-size: 2.3rem;
    color: var(--black);
    cursor: pointer;
  }
  
  .sidebar {
    width: 15rem;
    background: var(--white);
    height: 100vh;
    position: fixed;
    top: 0%;
    left: -100%;
    z-index: 50;
    transition: 0.3s all ease-in-out;
  }

  .span__pseudo{
      height:100vh;
      width: calc(100vw - 192px);
      top: 0%;
      left: -100%;
      position:absolute;
      background-color: var(--black);
      opacity: 0.5;
      z-index: -5;
  }
  
  .sidebar.active {
    left: 0;
  }

  .sidebar.active .span__pseudo{
    left: 192px;
  }
  
  .sidebar-items {
    font-size: 1rem;
    transition: 0.2s all ease-out;
  }
  
  .sidebar-item {
    list-style: none;
    margin-top: 1rem;
    width: 100%;
    height: 3rem;
  }
  
  .sidebar-item a {
    text-decoration: none;
    
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 2rem;
    height: 100%;
    border-radius: 5px;
    cursor: pointer;
    
  }
  
  .sidebar-item:hover {
    background: var(--primary);
  }

  