@import url( 'https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@font-face {
    font-family: 'Graphik';
    src: url('/public/font/GraphikRegular.otf') format('truetype');
}
@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('/public/font/HelveticaNowDisplay-Regular.ttf') format('truetype');
}
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Colors declaration */

@layer base {
   /* Scrollbar */
   ::-webkit-scrollbar {
     width: 12px;
   }
   
   /* Track */
   ::-webkit-scrollbar-track {
     background: transparent;
     border-radius: 20px;
   }
   
   ::-webkit-scrollbar-track:hover {
     background: transparent;
     transition: .5s ease-in-out;
   }
   
   /* Handle */
   ::-webkit-scrollbar-thumb {
      background: linear-gradient(to top, #FD015C, #e683a7);
      border-radius: 20px;
    }
}

:root {
    --primary: #CD0053;   
    --primary-light: #FD015C; 
    --base-color: #98A9BC;
    --text-color: #311136;
    --white: #ffffff;
    --danger: #FF4E4E;
    --warning: #D68917;
    --success: #6DD230;
}

body {
    font-family: 'HelveticaNowDisplay', sans-serif;
    background-color: #F5F5F5;
    position: relative;
    overflow-x:hidden;
}

.label-switch {
  cursor: pointer;
  text-indent: -9999px;
  width: 2.5rem;
  height: 1.5rem;
  background: var(--base-color);
  display: block;
  border-radius: 100px;
  position: relative;
}

.label-switch:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;
  width: 1.4rem;
  height: 1.4rem;
  background: #fff;
  box-shadow: 0px 0px 2.04287px rgba(0, 0, 0, 0.05), 
  0px 2.04287px 0.680956px rgba(0, 0, 0, 0.0510643), 
  0px 1.36191px 1.36191px rgba(0, 0, 0, 0.1), 
  0px 2.04287px 2.04287px rgba(0, 0, 0, 0.05);
  border-radius: 90px;
  transition: 0.3s;
}

.check-switch:checked + .label-switch {
  background: var(--primary);
}
 
.check-switch:checked + .label-switch:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.label-switch:active:after {
  width: 1.6rem;
}

.flex__center{
  display:flex;
  justify-content:center;
  align-items:center;
}