.auth_bg{
    background-color:white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    height: fit-content;
    width: 100vw;
    
}

.react-tel-input .flag-dropdown{
    background-color: var(--white) !important;
    border-right-color: var(--white) !important;
}

.phone_input{
    width: 100% !important;
    background-color: var(--white) !important;
    height: 2.8rem !important;
    border: 1px solid #D0D5DD !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    border-radius: 8px !important;
}

.flag-dropdown {
    border: 1px solid #D0D5DD !important;
    border-radius: none !important;
    border-bottom-left-radius: 8px !important;
    border-top-left-radius: 8px !important;
}

.selected-flag{
    border-left: none !important;
    border-right: none !important;
    border-radius: 8px !important;
}

.otp-container{
    margin: 0 auto !important; 
}

.otp-input{
    /* font-size:1.5rem !important; */
    /* width: 4.5rem !important;
    height: 4.5rem !important;   */
    border: 1px solid #D0D5DD;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;  
    
}

.otp-input:focus{
    border: 1px solid var(--primary) !important;
    outline: 3px solid rgba(205, 0, 83, 0.2) !important;
    /* filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05)); */

}

.check-bg{
    /* Success/100 */

    background: #fff;
    padding: 1rem;
    /* Success/50 */

    border: 10px solid #fff;
    animation:  check-animate linear infinite alternate-reverse;
    animation-duration: 2s;
    animation-delay: 0s;
}


@keyframes check-animate {
    
    50%{
        padding: 0.5rem;
        border-width: 5px;
    }

    75%{
        padding: 0.75rem;
        border-width: 7.5px;
    }
    
    100% {
        padding: 1rem;
        border-width: 10px;
    }
}

.forgot-enter {
    transform: translateX(-10rem);
    opacity: 0;
  }
  
  .forgot-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: all 200ms;
  }
  
  .forgot-exit {
    transform: translateX(0);
    opacity: 1;
  }
  
  .forgot-exit-active {
    transform: translateX(-10rem);
    opacity: 0;
    transition: all 200ms;
  }